<template>
  <div class="df-each">
    <!--  -->
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <el-select
          v-model="submitData.dept_guid"
          placeholder="请选择部门"
          size="mini"
        >
          <el-option
            v-for="item in deptList"
            :key="item.DeptGuid"
            :label="item.DeptName"
            :value="item.DeptGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-button size="mini" type="primary" @click="initPageData">
          查询
        </el-button>

        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button>
      </div>
    </div>
    <!--  -->
    <el-table
      id="exprot-table"
      :data="dataList"
      :max-height="tableMaxHeight"
      border
      style="width: 100%"
      show-summary
      stripe
      size="mini"
      v-loading="loading"
    >
      <el-table-column
        :fixed="isFixedRow"
        v-if="headList[0]"
        :label="headList[0].Name"
        width="180"
      >
        <template slot-scope="scope">
          {{
            scope.row[headList[0].DataKey]
              ? scope.row[headList[0].DataKey]
              : "--"
          }}
        </template>
      </el-table-column>
      <el-table-column
        :label="top.Name"
        v-for="(top, index) in topList"
        :key="index"
      >
        <template v-for="(list, listIndex) in headList">
          <el-table-column
            v-if="top.Code == list.TopCode"
            :key="list.DataKey"
            :label="list.Name"
            :prop="list.DataKey"
          >
            <template slot-scope="scope">
              {{
                scope.row[list.DataKey] >= 0 ? scope.row[list.DataKey] : "--"
              }}
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="hidePayMoney" class="hidePayMoney">
      隐藏的付款方式：{{ hidePayMoney }}
    </div>
  </div>
</template>

<script>
import api from "@/api/report.js";
export default {
  data() {
    return {
      options: [],
      tableMaxHeight: '',
      value: "",
      selectDate: [],
      tableData: [],
      submitData: {
        begin_date: "",
        end_date: "",
        subact: "query_data",
        dept_guid: "",
      },
      dataList: [],
      headList: [],
      topList: [],
      isFixedRow: true,
      loading: false,
      excelDisabled: false, // 导出按钮禁用
      hidePayMoney: "",
      deptList: [],
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.selectDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.initConditionData();
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 220;
    },

    async initConditionData() {
      try {
        let { data } = await api.initBusinessStoresStatis({
          subact: "init_data",
        });
        this.deptList = data.deptList;
        console.log(data);
      } catch (e) {}
    },

    async initPageData() {
      this.loading = true;
      try {
        this.submitData.begin_date =
          this.selectDate !== null
            ? this.selectDate[0]
            : this.util.getNowMonthToday();
        this.submitData.end_date =
          this.selectDate !== null
            ? this.selectDate[1]
            : this.util.getNowMonthToday();
        let { data } = await api.businesStoresStatis(this.submitData);
        let { DataList, HeadList, TopList, HidePayMoney } = data;
        this.dataList = DataList;
        this.headList = HeadList;
        this.topList = TopList;
        this.hidePayMoney = HidePayMoney;
      } catch (e) {}
      this.loading = false;
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "各门店业绩汇总表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-each {
  .df-row {
    display: flex;
    margin: 20px 0;
    .df-col {
      margin-right: 10px;
      max-width: 350px;
    }
  }

  .hidePayMoney {
    margin-top: 20px;
    color: red;
  }
}
</style>